import React from "react";
import { getTouchBook } from "../../asserts/images";

const GetTouch = () => {
  return (
    <>
      <section class="get_touch_sec sec_padding">
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-4 col-md-6">
              <div class="get_touch_title">
                <div class="sec_title">
                  <h2
                    data-aos="fade-up"
                    data-aos-offset="0"
                    data-aos-duration="2000"
                  >
                    Let's Be In
                    <br />
                    Touch!
                    <br />
                    Reach Out Now!
                  </h2>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-10 mx-auto">
              <div
                class="get_touch_img"
                data-aos="fade-right"
                data-aos-offset="0"
                data-aos-duration="1000"
              >
                <img
                  draggable={false}
                  src={getTouchBook}
                  class="img-fluid"
                  alt=""
                />
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="get_touch_form">
                <form action="https://www.falconbookwriting.com/webpages/FormController.php" method="post">
                  <div class="mb-3">
                    <input
                      type="text"
                      class="form-control"
                      name="Name"
                      placeholder="Name"
                    />
                  </div>
                  <div class="mb-3">
                    <input
                      type="email"
                      class="form-control"
                      name="Email"
                      placeholder="Email"
                    />
                  </div>
                  <div class="mb-3">
                    <input
                      type="tel"
                      class="form-control"
                      name="Number"
                      placeholder="Phone No"
                    />
                  </div>
                  <div class="mb-3">
                    <textarea
                      class="form-control"
                      name="Message"
                      placeholder="Message"
                    ></textarea>
                  </div>
                  <div className="label">
                    <div className="g-recaptcha" data-sitekey="6LeblcEqAAAAAIoiFqO75FS2sM3-84wGel9aq3ZM"></div>
                  </div>
                  <button type="submit" class="brand_btn">Submit</button>
                  
                  <input className="" type="hidden" name="ctry" value="" />
                          <input type="hidden" name="pc" value="" />
                          <input type="hidden" name="cip" value="" className="form-1-ip" />
                          <input type="hidden" name="hiddencapcha" value="" />
                          <input type="hidden" id="location" name="locationURL" value="https://www.falconbookwriting.com/" />
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default GetTouch;
