import React, { useState, useEffect } from "react";
import "./style.css"; // Create a CSS file for styles
import { american1, american2, american3, american4, american5, beijing1, beijing2, beijing3, beijing4, beijing5, frankfurt1, frankfurt2, frankfurt3, frankfurt4, frankfurt5, london1, london2, london3, london4, london5, newyork1, newyork2, newyork3, newyork4, newyork5 } from "../../asserts/images";



const Exhibition = () => {
    const [activeTab, setActiveTab] = useState("American");
  
    const tabData = [
      {
        id: "American",
        title: "American Library Annual Conference",
        images: [
            american1,
            american2,
            american3,
            american4,
            american5,
        ],
      },
      {
        id: "Beijing",
        title: "Beijing Book Fair 2024",
        images: [
            beijing1,
            beijing2,
            beijing3,
            beijing4,
            beijing5,
        ],
      },
      {
        id: "Food",
        title: "Frankfurt International Book Fair 2024",
        images: [
            frankfurt1,
            frankfurt2,
            frankfurt3,
            frankfurt4,
            frankfurt5,
        ],
      },
      {
        id: "Hair",
        title: "London Book Fair 2024",
        images: [
            london1,
            london2,
            london3,
            london4,
            london5
        ],
      },
      {
        id: "Clothing",
        title: "New York Library Association Conference 2024",
        images: [
            newyork1,
            newyork2,
            newyork3,
            newyork4,
            newyork5,
        ],
      },
    ];
  
    return (
      <section className="our__portfolio__sec padding__120">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="heading__center">
                <h6>Featured Projects</h6>
                <h2>Take a Look at Some of <br/> <span>Our Work</span></h2>
              </div>
            </div>
          </div>
          <div className="row pt-5">
            <div className="col-sm-4">
              <div className="Portfolio__Tb__LiSt">
                <ul className="nav nav-tabs" role="tablist">
                  {tabData.map((tab) => (
                    <li className="nav-item" key={tab.id}>
                      <button
                        className={`nav-link ${activeTab === tab.id ? "active" : ""}`}
                        onClick={() => setActiveTab(tab.id)}
                      >
                        {tab.title}
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="col-sm-8">
              <div className="tab-content">
                {tabData.map((tab) => (
                  <div
                    key={tab.id}
                    className={`tab-pane fade ${activeTab === tab.id ? "show active" : ""}`}
                  >
                    <div className="row">
                      {tab.images.map((image, index) => (
                        <div className="col-sm-6" key={index}>
                          <div className="portfolio__sec__Main">
                            <div className="portfolio__img">
                              <img
                                src={image}
                                className="img-responsive"
                                alt="Portfolio"
                              />
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  };
  
  export default Exhibition;